import React from "react"

import Layout from "../layouts"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page not found</h1>
    <p>Sorry, you've navigated to a page that doesn't exist. Please head <a href="/">home</a> and try again.</p>
  </Layout>
)

export default NotFoundPage
